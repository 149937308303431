import React from 'react';
import Helmet from 'react-helmet';
import 'styles/master.scss';
const SuccessPage = () => (
  // <ThemeProvider theme={theme}>
  <>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    {/* <GlobalStyles /> */}
    <main>
      <div className="container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
            textAlign: 'center',
          }}>
          <h2 className="blue" style={{ marginBottom: 60 }}>
            You&apos;ve been successfully added to our special list!
          </h2>
          <img
            src="/assets/img/ruttl-logo.svg"
            alt="ruttl logo"
            width="100px"
          />
        </div>
      </div>
    </main>
  </>
  // </ThemeProvider>
);

export default SuccessPage;
